@import (reference) "braeburn-references.less";

/* Orchard 1.9 layouts */
html.boxed-layout {
    .orchard-layouts-root .table {
        .container;
    }
}

html.fluid-layout {
    .orchard-layouts-root .table {
        padding: 0 15px;
    }
}

@media (max-width: @screen-xs-max) {
    .span-1.cell {
        .make-xs-column(12);
    }

    .span-2.cell {
        .make-xs-column(12);
    }

    .span-3.cell {
        .make-xs-column(12);
    }

    .span-4.cell {
        .make-xs-column(12);
    }

    .span-5.cell {
        .make-xs-column(12);
    }

    .span-6.cell {
        .make-xs-column(12);
    }

    .span-7.cell {
        .make-xs-column(12);
    }

    .span-8.cell {
        .make-xs-column(12);
    }

    .span-9.cell {
        .make-xs-column(12);
    }

    .span-10.cell {
        .make-xs-column(12);
    }

    .span-11.cell {
        .make-xs-column(12);
    }

    .span-12.cell {
        .make-xs-column(12);
    }

    .row > .cell {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: @screen-sm-min) {
    .span-1.cell {
        .make-sm-column(1);
    }

    .span-2.cell {
        .make-sm-column(2);
    }

    .span-3.cell {
        .make-sm-column(3);
    }

    .span-4.cell {
        .make-sm-column(4);
    }

    .span-5.cell {
        .make-sm-column(5);
    }

    .span-6.cell {
        .make-sm-column(6);
    }

    .span-7.cell {
        .make-sm-column(7);
    }

    .span-8.cell {
        .make-sm-column(8);
    }

    .span-9.cell {
        .make-sm-column(9);
    }

    .span-10.cell {
        .make-sm-column(10);
    }

    .span-11.cell {
        .make-sm-column(11);
    }

    .span-12.cell {
        .make-sm-column(12);
    }
}

@media (min-width: @screen-md-min) {
    .span-1.cell {
        .make-md-column(1);
    }

    .span-2.cell {
        .make-md-column(2);
    }

    .span-3.cell {
        .make-md-column(3);
    }

    .span-4.cell {
        .make-md-column(4);
    }

    .span-5.cell {
        .make-md-column(5);
    }

    .span-6.cell {
        .make-md-column(6);
    }

    .span-7.cell {
        .make-md-column(7);
    }

    .span-8.cell {
        .make-md-column(8);
    }

    .span-9.cell {
        .make-md-column(9);
    }

    .span-10.cell {
        .make-md-column(10);
    }

    .span-11.cell {
        .make-md-column(11);
    }

    .span-12.cell {
        .make-md-column(12);
    }
}

@media (min-width: @screen-lg-min) {
    .span-1.cell {
        .make-lg-column(1);
    }

    .span-2.cell {
        .make-lg-column(2);
    }

    .span-3.cell {
        .make-lg-column(3);
    }

    .span-4.cell {
        .make-lg-column(4);
    }

    .span-5.cell {
        .make-lg-column(5);
    }

    .span-6.cell {
        .make-lg-column(6);
    }

    .span-7.cell {
        .make-lg-column(7);
    }

    .span-8.cell {
        .make-lg-column(8);
    }

    .span-9.cell {
        .make-lg-column(9);
    }

    .span-10.cell {
        .make-lg-column(10);
    }

    .span-11.cell {
        .make-lg-column(11);
    }

    .span-12.cell {
        .make-lg-column(12);
    }
}
