@import (reference) "braeburn-references.less";
@import "braeburn-colors";

@menu-collapse-width: 1020px;

// Navigation properties
.navbar-default {
    background-color: @colorNavbar;
    border:none;
    border-bottom:solid 1px #ededed;
    box-shadow: @boxShadows;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border: none;
}
.navbar-form {
    padding: 10px 15px;
    margin:8px -15px;
    border:none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.navbar-collapse {
    max-height: 340px;
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    border:none;
    box-shadow: none;
    -webkit-overflow-scrolling: touch;
}
.navbar-brand {
    padding:0;
    img {
        max-width: 150px;
    }
}

#footer{
    h3{
        color:@colorCartCount;
    }
    a{
        color:@colorDarkText;
        &:hover{
            color:@colorBodyText;
        }

        i{
            color:@ciderButtonsAndLinks;
        }
    }
}

.base-menu-properties {
    position: relative;
    display: block;
    padding: 10px 15px;
}


//Base Menu properties
.nav{
    &>li{
        &>a,
        &>.dropdown-bar > .expand-arrow-companion > a {
            .base-menu-properties()
        }
    }
}

.dropdown-menu {
    box-shadow: @boxShadows;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    list-style: none;
    background-color: @colorDropdownBackground;
    border: none;
    border-radius: 0;

    &>li {
        &> a,
        &>.dropdown-bar > .expand-arrow-companion > a {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: normal;
            line-height: 1.428571429;
            color: @colorDarkText;
            padding-left: 5px;
            padding-right: 10px;
            white-space:normal;

        }
    }
}

aside {
    .dropdown-bar {
        width:100%;
        float:left;
        & > .expand-arrow-companion {
            & > a {
                margin-left: 0!important;
            }
        }
    }

    .dropdown.open .dropdown .expand-arrow{
        margin-left:30px!important;
    }

    .dropdown.open .dropdown.open .dropdown .expand-arrow{
        margin-left:60px!important;
    }

    .dropdown.open .dropdown.open .dropdown.open .dropdown .expand-arrow{
        margin-left:90px!important;
    }

    .dropdown .dropdown-menu > li > a{
        margin-left:50px!important;
    }

    .dropdown.open .dropdown .dropdown-menu > li > a{
        margin-left:80px!important;
    }

    .dropdown.open .dropdown.open .dropdown .dropdown-menu > li > a{
        margin-left:110px!important;
    }

    .dropdown.open .dropdown.open .dropdown.open .dropdown .dropdown-menu > li > a{
        margin-left:140px!important;
    }

    .expand-arrow {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-top: 5px;
        padding:0px;
        float:left;
        padding-left: 6px;
        margin-left: 5px;
        border-radius: 20px;
        user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        -moz-user-select:none;
        .fa-angle-right {
            padding-left:3px;
        }

        &:hover{
            background: rgba(255,255,255,.4);
        }
    }

    .expand-arrow-companion {
        float:left;
    }
}
// Menu in side bar
.zone-aside-second, .zone-aside-first{
    .menu{

        & > li{
            a, 
            .dropdown-bar > .expand-arrow-companion > a,
            & > ul > li > a,
            & > ul > li > .dropdown-bar > .expand-arrow-companion > a {
                color: #888888;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 5px;
                clear: both;

                &:hover, &:focus{
                    background:transparent;
                    color:@colorDarkText;
                }
            }

            &.active{
                &>a,
                &>.dropdown-bar > .expand-arrow-companion > a{
                    color: @alertRed;
                }
            }

            &.last 
            {
                padding-bottom:10px;
            }

            &>a
            {
                margin-left:25px;
            }

            width:100%;
            float:right;

            .dropdown-menu{
                width:100%;
                box-shadow:none!important;

                & > .active{
                   & > a,
                   & > .dropdown-bar > .expand-arrow-companion > a{
                       &, &:hover, &:active{
                            text-decoration: none;
                            color:@alertRed;
                            background-color: transparent;
                            outline: 0;
                       }
                    }
                }
            }
        }
    }
}

.nav-bar-button-text {
    padding-top: 24px;
    padding-bottom: 25px;
    color: @colorDarkText;
    background-color: transparent;
    border: solid 1px transparent;

    &:hover, &:focus {
        color: @colorDarkText;
        background-color: #fff;
        border-left: @boxBorders;
        border-right: @boxBorders;
        z-index: 1001;
    }
}

// Menu in navigation zone
.zone-navigation{
    .menu{
        .expand-arrow {
            display:none;
        }
        .expand-arrow-companion {
            width:100%;
        }

        &>.active > .dropdown-bar > .expand-arrow-companion > a,
        &>li > .dropdown-bar > .expand-arrow-companion  > a,
        &>.open > a,
        &>.active > a,
        &>li > a,
        &.menu-my-account-links > a {
            .nav-bar-button-text()
        }

        &>.open > .dropdown-bar > .expand-arrow-companion > a
        {
            color: @colorDarkText;
            background-color: #fff;
            border-left: @boxBorders;
            border-right: @boxBorders;
            z-index:1001;
        }

        .dropdown-menu{
            border: @boxBorders;
            border-top: none;

            a {
                color: #777;
            }
            a:hover {
                color: #000;
                background: rgba(0,0,0,.075);
            }

            &>.active{
                &>a{
                    &:hover, &:focus, &{
                        background-color:@colorNavbar;
                    }
                }
            }

            &>li > a,
            &>li > .dropdown-bar > .expand-arrow-companion > a {
                margin-left:0;
                padding-left:10px;
            }
        }
    }

    .widget-search-form{
        float:left;
        width:18%;
    }

    .purchase-product {
        .base-menu-properties();
        .nav-bar-button-text();
        display: inline-block;
        float:left;
        div {
            color: @colorDarkText;
        }
    }
}

// disorganized menu stuff.  TODO: organize into above LESS
aside .open .dropdown-menu, aside .active .dropdown-menu {
    position: static!important;
    float: none!important;
    width: auto!important;
    margin-top: 0!important;
    background-color: transparent!important;
    border: 0!important;
    box-shadow: none!important;
}
.dropdown-menu & &>li & &>a:hover,.dropdown-menu & &>li & &>a:focus {
    color:#888888;
    text-decoration: none;
    background-color: #f5f5f5;
}

.zone-navigation .menu {
    .navbar-form.navbar-right:last-child {
        margin-top: 16px;
        margin-right: 0;
    }
}

.dropdown{
    .open{
        clear:both;
    }
}

.menu-my-account-links{
    float:right;
}

.widget-navigation .dropdown-menu {
    .mini-cart {
        width: 368px;

        .mini-cart-options .checkout-button {
            // Override the default navigation button styling for the cart widget
            &, &:hover {
                color: @colorButtonPrimaryText;
            }

            &.btn-primary {
                &, &:hover {
                    background: @ciderButtonsAndLinks;
                }
            }

            &.btn-default {
                &, &:hover {
                    background: @colorButtonDefault;
                }
            }

            margin-top: 15px;
        }
    }
}
/*BROWSER*/

@media (max-width:1100px){
    .navbar>.container .navbar-brand {
        width:auto;
        margin-left: -35px;
    }
}

// Make the navbar collapse sooner to fix the navbar expanding to two rows at medium widths
@media (max-width: @menu-collapse-width) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
  		display:block !important;
	}
}


@media (max-width: @screen-md-min){
    // Menu in navigation zone
    .zone-navigation{
        .menu{
            &>.active > .dropdown-bar > .expand-arrow-companion > a,
            &>li > .dropdown-bar > .expand-arrow-companion  > a,
            &>.open > a,
            &>.active > a,
            &>li > a {
                padding: 20px 10px;
            }
        }

        .widget-search-form{
            width:15%;
        }

        .input-group-btn .btn-default{
            margin:0;
            padding:5px;
        }
    }

    // Menu in side bar
    .zone-aside-second, .zone-aside-first   {
        .menu{
            max-width:none;

            &>li{
                max-width:none;
            }
        }
    }

   /*KINDLE FIRE*/
}


@media(max-width: @menu-collapse-width) {

    .navbar-brand {
        padding:5px;
    }
    .navbar-brand img {
        vertical-align: middle;
        margin-bottom:0;
    }

    .nav>li{
        clear:both;
    }

    // Menu in navigation zone
    .zone-navigation{
        .menu{
            &>.active > .dropdown-bar > .expand-arrow-companion > a,
            &>li > .dropdown-bar > .expand-arrow-companion  > a,
            &>.open > a,
            &>.active > a,
            &>li > a {
                padding-top: 5px;
                padding-bottom: 5px;
                border: none;
                color:@colorBodyText;

                &:hover, &:focus{
                    border:none;
                    background:transparent;
                    color:@colorDarkText;
                }
            }
            &>.open > .dropdown-bar > .expand-arrow-companion > a
            {
                border:none;
                background:transparent;
                color:@colorDarkText;
            }

            .dropdown-menu{
                border:none;
            }
        }
    }

    .menu-my-account-links{
        float:none;
    }

     .navbar-default .navbar-nav .open .dropdown-menu &>.active &>a, 
     .navbar-default .navbar-nav .open .dropdown-menu &>.active &>a:hover, 
     .navbar-default .navbar-nav .open .dropdown-menu &>.active &>a:focus {
        color:@colorDarkText;
        background-color: transparent;
    }
    .navbar-nav .open .dropdown-menu &>li &>a, 
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
    }
    .zone-navigation .menu .dropdown-menu &>li &>a,
    .zone-navigation .menu .dropdown-menu &>li &>.dropdown-bar &>.expand-arrow-companion,
    .zone-navigation .menu .navbar-nav .open .dropdown-menu &>li &>a, 
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding-left:30px!important;
    }
    .zone-navigation .menu {
        margin-left: -15px;
        margin-right: -15px;
    }

    .zone-navigation .widget-cart-widget {
        float: none;

        .shopping-cart-button {
            min-height: unset;
            float: left;

            .dropdown-menu {
                left: unset;
                right: unset;
            }
        }
    }

    /*IPAD VERTICAL*/
}


@media (max-width:400px){
    .navbar>.container .navbar-brand {
        margin-left: 0;
    }
}