@charset "utf-8";

@alertRed: #c44830;
@alertRedHover: #f27e68;
@ciderButtonsAndLinks: #6BB697;
@ciderButtonsAndLinksLight: #82D4B3;
@ciderDisabledButton: #b6cdc3;
@ciderToggleButtonLight:#eef7f3;
@colorLayoutBackground: #fff;
@colorBodyBackground: #fff;
@colorBodyText: #777777; 
@colorLink: #aaaaaa;
@colorFooterText: @colorLink;
@colorRegularParagraphText: #555555;
@colorDropdownBackground: @colorLayoutBackground;
@colorDarkText: #333333;
@colorLightText: #dedede;
@colorBorder: #dedede;
@colorNavbar: #e0e0e0;
@colorHighlight: rgba(132, 223, 110, 1);
@colorButtonPrimaryText: #fff;
@colorButtonDefault: #888888;
@colorCartCount:#689B86;
@colorPreFooter:rgb(205, 205, 205);
@colorNoteHeader: #bfbfbf;
@colorShadedNote: #e6e6e6;

@horizontalSeparator: solid 1px #eee;
@headingSeparator: solid 2px @colorBorder;

@boxBorders: solid 1px #cccccc;
@listBorders: @boxBorders;
@boxShadows: 0 0 60px 0 rgba(0,0,0, .075);
@thinBoxShadows: 0 0 15px 0 rgba(0,0,0, .075);
